
import { updateAppConfig } from '#app'
import { defuFn } from '/src/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "775ca666-4bf9-452f-b13d-be12caef8aa4"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/src/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
